import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-ec3f7420"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "dateRangePicker" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DateRangePicker = _resolveComponent("DateRangePicker")!
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_el_table = _resolveComponent("el-table")!
  const _component_el_pagination = _resolveComponent("el-pagination")!
  const _component_el_main = _resolveComponent("el-main")!
  const _directive_loading = _resolveDirective("loading")!

  return (_openBlock(), _createBlock(_component_el_main, null, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_DateRangePicker, {
          dateRange: _ctx.dateRange,
          onChange: _ctx.setDateRange
        }, null, 8, ["dateRange", "onChange"])
      ]),
      _withDirectives(_createVNode(_component_el_table, {
        data: _ctx.data?.data,
        border: "",
        fit: "",
        "highlight-current-row": "",
        style: {"width":"100%"}
      }, {
        default: _withCtx(() => [
          _createVNode(_component_el_table_column, {
            align: "center",
            label: "Date",
            width: "110"
          }, {
            default: _withCtx(({row}) => [
              _createTextVNode(_toDisplayString(row.date), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_el_table_column, {
            align: "center",
            label: "Daily Active User",
            width: "140"
          }, {
            default: _withCtx(({row}) => [
              _createTextVNode(_toDisplayString(row.daily_active_user), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_el_table_column, {
            align: "center",
            label: "Subscribers"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_table_column, {
                align: "center",
                label: "Channel"
              }, {
                default: _withCtx(({row}) => [
                  _createTextVNode(_toDisplayString(row.channel_subscribers), 1)
                ]),
                _: 1
              }),
              _createVNode(_component_el_table_column, {
                align: "center",
                label: "Direct"
              }, {
                default: _withCtx(({row}) => [
                  _createTextVNode(_toDisplayString(row.direct_subscribers), 1)
                ]),
                _: 1
              }),
              _createVNode(_component_el_table_column, {
                align: "center",
                label: "New"
              }, {
                default: _withCtx(({row}) => [
                  _createTextVNode(_toDisplayString(row.new_subscribers), 1)
                ]),
                _: 1
              }),
              _createVNode(_component_el_table_column, {
                align: "center",
                label: "Renew"
              }, {
                default: _withCtx(({row}) => [
                  _createTextVNode(_toDisplayString(row.renew_subscribers), 1)
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_el_table_column, {
            align: "center",
            label: "Revenue"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_table_column, {
                align: "center",
                label: "Sum"
              }, {
                default: _withCtx(({row}) => [
                  _createTextVNode(_toDisplayString(row.new_revenue + row.regular_revenue + row.channel_revenue + row.direct_revenue), 1)
                ]),
                _: 1
              }),
              _createVNode(_component_el_table_column, {
                align: "center",
                label: "Channel"
              }, {
                default: _withCtx(({row}) => [
                  _createTextVNode(_toDisplayString(row.channel_revenue), 1)
                ]),
                _: 1
              }),
              _createVNode(_component_el_table_column, {
                align: "center",
                label: "Direct"
              }, {
                default: _withCtx(({row}) => [
                  _createTextVNode(_toDisplayString(row.direct_revenue), 1)
                ]),
                _: 1
              }),
              _createVNode(_component_el_table_column, {
                align: "center",
                label: "New"
              }, {
                default: _withCtx(({row}) => [
                  _createTextVNode(_toDisplayString(row.new_revenue), 1)
                ]),
                _: 1
              }),
              _createVNode(_component_el_table_column, {
                align: "center",
                label: "Renew"
              }, {
                default: _withCtx(({row}) => [
                  _createTextVNode(_toDisplayString(row.renew_revenue), 1)
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_el_table_column, {
            align: "center",
            label: "Subscription"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_table_column, {
                align: "center",
                label: "Orders Count",
                width: "115"
              }, {
                default: _withCtx(({row}) => [
                  _createTextVNode(_toDisplayString(row.orders_count), 1)
                ]),
                _: 1
              }),
              _createVNode(_component_el_table_column, {
                align: "center",
                label: "Success Orders Count",
                width: "190"
              }, {
                default: _withCtx(({row}) => [
                  _createTextVNode(_toDisplayString(row.success_orders_count), 1)
                ]),
                _: 1
              }),
              _createVNode(_component_el_table_column, {
                align: "center",
                label: "Success Rate",
                width: "130"
              }, {
                default: _withCtx(({row}) => [
                  _createTextVNode(_toDisplayString(_ctx.formatOrderSuccessRate(row.order_success_rate)), 1)
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_el_table_column, {
            align: "center",
            label: "Refund"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_table_column, {
                align: "center",
                label: "Count"
              }, {
                default: _withCtx(({row}) => [
                  _createTextVNode(_toDisplayString(row.refund_count), 1)
                ]),
                _: 1
              }),
              _createVNode(_component_el_table_column, {
                align: "center",
                label: "Amount"
              }, {
                default: _withCtx(({row}) => [
                  _createTextVNode(_toDisplayString(row.refund_amount), 1)
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["data"]), [
        [_directive_loading, _ctx.isLoading || _ctx.isFetching]
      ]),
      _createVNode(_component_el_pagination, {
        currentPage: _ctx.page,
        "onUpdate:currentPage": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.page) = $event)),
        "page-size": _ctx.data?.meta?.per_page,
        layout: "prev, pager, next, jumper",
        total: _ctx.data?.meta?.total
      }, null, 8, ["currentPage", "page-size", "total"])
    ]),
    _: 1
  }))
}